require('./bootstrap');
import 'jsgrid/dist/jsgrid.js';
import 'jquery-ui/ui/widgets/dialog.js';
import 'jquery-ui/ui/widgets/tabs.js';
import 'jquery-ui/ui/widgets/sortable.js';
import 'jquery-validation/dist/jquery.validate.js';
import 'select2/dist/js/select2';
import 'orgchart/dist/js/jquery.orgchart';
import 'html2canvas/dist/html2canvas';
import 'jspdf/dist/jspdf.es';
import 'bootstrap4-toggle/js/bootstrap4-toggle.min';
import 'jquery.flipster/dist/jquery.flipster.min';
import 'canvasjs/dist/jquery.canvasjs.min';
import './nav.js';
import 'jquery-raty-js/lib/jquery.raty'
require('leaflet');

