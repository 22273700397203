$(function() {
    //Begin management of menu display on mobile
    $('.close-menu, #menuOverlay').on('click', function() {
        $('.navbar-collapse').removeClass('active');
        $('#menuOverlay').removeClass('active');
        $('.open-menu').show();
        $('.close-menu').hide();
    });
    $('.open-menu').on("click", function (e) {
        e.preventDefault();
        $('.navbar-collapse').addClass('active');
        $('#menuOverlay').addClass('active');
        // close opened sub-menus
        $('.collapse.show').toggleClass('show');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
        $('.open-menu').hide();
        $('.close-menu').show();
    });
    //End management of menu display on mobile
    //Begin management of switch amin group
    $('#adminMetaGroup').on("change", function (e) {
        //Manage ajax call to Post data's to swith group of super Admin user
        let formData = $('#formAdminSwitchGroup').serialize();
        let formAction = $('#formAdminSwitchGroup').attr('action');
        $.ajax({
            type: "POST",
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: formAction,
            data: formData,
            success: function(response){
                if(response && typeof(response.reload) !== 'undefined' && response.reload === 1){
                    location.reload();
                }
            }
        });
        event.preventDefault();
        event.stopPropagation();
        return false;

    });
    //End management of switch amin group
});
